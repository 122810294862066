//icons

//migrationfromfont-awesomev3tov4
[class^="icon-"] {
    @extend .icon;
}

//.icon-ban-circle
//	@extend .icon-ban
.icon-bar-chart {
    @extend .icon-bar-chart-o;
}

.icon-beaker {
    @extend .icon-flask;
}

.icon-bell {
    @extend .icon-bell-o;
}

.icon-bell-alt {
    @extend .icon-bell;
}

.icon-bitbucket-sign {
    @extend .icon-bitbucket-square;
}

.icon-bookmark-empty {
    @extend .icon-bookmark-o;
}

.icon-building {
    @extend .icon-building-o;
}

.icon-calendar-empty {
    @extend .icon-calendar-o;
}

.icon-check-empty {
    @extend .icon-square-o;
}

.icon-check-minus {
    @extend .icon-minus-square-o;
}

.icon-check-sign {
    @extend .icon-check-square;
}

.icon-chevron-sign-down {
    @extend .icon-chevron-circle-down;
}

.icon-chevron-sign-left {
    @extend .icon-chevron-circle-left;
}

.icon-chevron-sign-right {
    @extend .icon-chevron-circle-right;
}

.icon-chevron-sign-up {
    @extend .icon-chevron-circle-up;
}

.icon-circle-arrow-down {
    @extend .icon-arrow-circle-down;
}

.icon-circle-arrow-left {
    @extend .icon-arrow-circle-left;
}

.icon-circle-arrow-right {
    @extend .icon-arrow-circle-right;
}

.icon-circle-arrow-up {
    @extend .icon-arrow-circle-up;
}

.icon-circle-blank {
    @extend .icon-circle-o;
}

.icon-cny {
    @extend .icon-rub;
}

.icon-collapse-alt {
    @extend .icon-minus-square-o;
}

.icon-collapse-top {
    @extend .icon-caret-square-o-up;
}

.icon-collapse {
    @extend .icon-caret-square-o-down;
}

.icon-comment-alt {
    @extend .icon-comment-o;
}

.icon-comments-alt {
    @extend .icon-comments-o;
}

.icon-copy {
    @extend .icon-files-o;
}

.icon-cut {
    @extend .icon-scissors;
}

.icon-dashboard {
    @extend .icon-tachometer;
}

.icon-double-angle-down {
    @extend .icon-angle-double-down;
}

.icon-double-angle-left {
    @extend .icon-angle-double-left;
}

.icon-double-angle-right {
    @extend .icon-angle-double-right;
}

.icon-double-angle-up {
    @extend .icon-angle-double-up;
}

.icon-download {
    @extend .icon-arrow-circle-o-down;
}

.icon-download-alt {
    @extend .icon-download;
}

.icon-edit-sign {
    @extend .icon-pencil-square;
}

.icon-edit {
    @extend .icon-pencil-square-o;
}

.icon-ellipsis-horizontal {
    @extend .icon-ellipsis-h;
}

.icon-ellipsis-vertical {
    @extend .icon-ellipsis-v;
}

.icon-envelope-alt {
    @extend .icon-envelope-o;
}

.icon-exclamation-sign {
    @extend .icon-exclamation-circle;
}

.icon-expand-alt {
    @extend .icon-plus-square-o;
}

.icon-external-link-sign {
    @extend .icon-external-link-square;
}

.icon-eye-close {
    @extend .icon-eye-slash;
}

.icon-eye-open {
    @extend .icon-eye;
}

.icon-facebook-sign {
    @extend .icon-facebook-square;
}

.icon-facetime-video {
    @extend .icon-video-camera;
}

.icon-file-alt {
    @extend .icon-file-o;
}

.icon-file-text-alt {
    @extend .icon-file-text-o;
}

.icon-flag-alt {
    @extend .icon-flag-o;
}

.icon-folder-close-alt {
    @extend .icon-folder-o;
}

.icon-folder-close {
    @extend .icon-folder;
}

.icon-folder-open-alt {
    @extend .icon-folder-open-o;
}

.icon-food {
    @extend .icon-cutlery;
}

.icon-frown {
    @extend .icon-frown-o;
}

.icon-fullscreen {
    @extend .icon-arrows-alt;
}

.icon-github-sign {
    @extend .icon-github-square;
}

.icon-google-plus-sign {
    @extend .icon-google-plus-square;
}

.icon-group {
    @extend .icon-users;
}

.icon-h-sign {
    @extend .icon-h-square;
}

.icon-hand-down {
    @extend .icon-hand-o-down;
}

.icon-hand-left {
    @extend .icon-hand-o-left;
}

.icon-hand-right {
    @extend .icon-hand-o-right;
}

.icon-hand-up {
    @extend .icon-hand-o-up;
}

.icon-hdd {
    @extend .icon-hdd-o;
}

.icon-heart-empty {
    @extend .icon-heart-o;
}

.icon-hospital {
    @extend .icon-hospital-o;
}

.icon-indent-left {
    @extend .icon-outdent;
}

.icon-indent-right {
    @extend .icon-indent;
}

.icon-info-sign {
    @extend .icon-info-circle;
}

.icon-keyboard {
    @extend .icon-keyboard-o;
}

.icon-legal {
    @extend .icon-gavel;
}

.icon-lemon {
    @extend .icon-lemon-o;
}

.icon-lightbulb {
    @extend .icon-lightbulb-o;
}

.icon-linkedin-sign {
    @extend .icon-linkedin-square;
}

.icon-meh {
    @extend .icon-meh-o;
}

.icon-microphone-off {
    @extend .icon-microphone-slash;
}

.icon-minus-sign-alt {
    @extend .icon-minus-square;
}

.icon-minus-sign {
    @extend .icon-minus-circle;
}

.icon-mobile-phone {
    @extend .icon-mobile;
}

.icon-moon {
    @extend .icon-moon-o;
}

.icon-move {
    @extend .icon-arrows;
}

.icon-off {
    @extend .icon-power-off;
}

.icon-ok-circle {
    @extend .icon-check-circle-o;
}

.icon-ok-sign {
    @extend .icon-check-circle;
}

.icon-paper-clip {
    @extend .icon-paperclip;
}

.icon-paste {
    @extend .icon-clipboard;
}

.icon-phone-sign {
    @extend .icon-phone-square;
}

.icon-picture {
    @extend .icon-picture-o;
}

.icon-pinterest-sign {
    @extend .icon-pinterest-square;
}

.icon-play-circle {
    @extend .icon-play-circle-o;
}

.icon-play-sign {
    @extend .icon-play-circle;
}

.icon-plus-sign-alt {
    @extend .icon-plus-square;
}

.icon-plus-sign {
    @extend .icon-plus-circle;
}

.icon-pushpin {
    @extend .icon-thumb-tack;
}

.icon-question-sign {
    @extend .icon-question-circle;
}

.icon-remove-circle {
    @extend .icon-times-circle-o;
}

.icon-remove-sign {
    @extend .icon-times-circle;
}

.icon-remove {
    @extend .icon-times;
}

.icon-reorder {
    @extend .icon-bars;
}

.icon-resize-full {
    @extend .icon-expand;
}

.icon-resize-horizontal {
    @extend .icon-arrows-h;
}

.icon-resize-small {
    @extend .icon-compress;
}

.icon-resize-vertical {
    @extend .icon-arrows-v;
}

.icon-rss-sign {
    @extend .icon-rss-square;
}

.icon-save {
    @extend .icon-floppy-o;
}

.icon-screenshot {
    @extend .icon-crosshairs;
}

.icon-share-alt {
    @extend .icon-share;
}

.icon-share-sign {
    @extend .icon-share-square;
}

.icon-share {
    @extend .icon-share-square-o;
}

.icon-sign-blank {
    @extend .icon-square;
}

.icon-signin {
    @extend .icon-sign-in;
}

.icon-signout {
    @extend .icon-sign-out;
}

.icon-smile {
    @extend .icon-smile-o;
}

.icon-sort-by-alphabet-alt {
    @extend .icon-sort-alpha-desc;
}

.icon-sort-by-alphabet {
    @extend .icon-sort-alpha-asc;
}

.icon-sort-by-attributes-alt {
    @extend .icon-sort-amount-desc;
}

.icon-sort-by-attributes {
    @extend .icon-sort-amount-asc;
}

.icon-sort-by-order-alt {
    @extend .icon-sort-numeric-desc;
}

.icon-sort-by-order {
    @extend .icon-sort-numeric-asc;
}

.icon-sort-down {
    @extend .icon-sort-asc;
}

.icon-sort-up {
    @extend .icon-sort-desc;
}

.icon-stackexchange {
    @extend .icon-stack-overflow;
}

.icon-star-empty {
    @extend .icon-star-o;
}

.icon-star-half-empty {
    @extend .icon-star-half-o;
}

.icon-sun {
    @extend .icon-sun-o;
}

.icon-thumbs-down-alt {
    @extend .icon-thumbs-o-down;
}

.icon-thumbs-up-alt {
    @extend .icon-thumbs-o-up;
}

.icon-time {
    @extend .icon-clock-o;
}

.icon-trash {
    @extend .icon-trash-o;
}

.icon-tumblr-sign {
    @extend .icon-tumblr-square;
}

.icon-twitter-sign {
    @extend .icon-twitter-square;
}

.icon-unlink {
    @extend .icon-chain-broken;
}

.icon-upload {
    @extend .icon-arrow-circle-o-up;
}

.icon-upload-alt {
    @extend .icon-upload;
}

.icon-warning-sign {
    @extend .icon-exclamation-triangle;
}

.icon-xing-sign {
    @extend .icon-xing-square;
}

.icon-youtube-sign {
    @extend .icon-youtube-square;
}

.icon-zoom-in {
    @extend .icon-search-plus;
}

.icon-zoom-out {
    @extend .icon-search-minus;
}
