//
// Badges
// --------------------------------------------------


// Base classes
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: $font-size-small;
  font-weight: $badge-font-weight;
  color: $badge-color;
  line-height: $badge-line-height;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: $badge-bg;
  border-radius: $badge-border-radius;

  // Empty badges collapse automatically (not available in IE8)
  &:empty {
    display: none;
  }
}

// Hover state, but only for links
a.badge {
  &:hover,
  &:focus {
    color: $badge-link-hover-color;
    text-decoration: none;
    cursor: pointer;
  }
}

// Quick fix for labels/badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// Account for counters in navs
a.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: $badge-active-color;
  background-color: $badge-active-bg;
}
.nav-pills > li > a > .badge {
  margin-left: 3px;
}
